<template>
  <div id="app">
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      mode="out-in"
    >
      <router-view />
    </transition>

  </div>
</template>

<script src="./assets/icon/iconfont.js"></script>
<script>

export default {

}
</script>


<style lang="less">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.el-menu{
  border-right: 0 !important;
}

.index,.video .el-dialog {

.el-dialog__header {
  padding: 0;
}
  .el-dialog__body{
    padding: 0;
  }

}

@import url(./assets/css/resect.css);

</style>

import router from '../router/index' //跟组件中的this.$router是同一个对象

const CATEGORY_KEY = "STUDY_CATEGORY";
const AUTO_NEXT_KEY = "AUTO_NEXT";
const AUTO_REMOVE_KEY = "AUTO_REMOVE";
const REMEMBER_MODE_KEY = "REMEMBER_MODE";
export default {
  namespaced:true,
  state:()=>({
     //存放用户登录信息
    userInfo:JSON.parse(sessionStorage.getItem("user")||'{}'),
    //科目id
    category:JSON.parse(localStorage.getItem(CATEGORY_KEY)||'{}'),
    //答对自动下一题
    autoNext:localStorage.getItem(AUTO_NEXT_KEY)||'0',
     //答对自动移除错题
    autoRemove:localStorage.getItem(AUTO_REMOVE_KEY)||'0',
    //背题模式
    rememberMode:localStorage.getItem(REMEMBER_MODE_KEY)||'0'

  }),
  mutations:{
    initUinfoMut(state,info){
      state.userInfo = info
    },
      //保存科目
    saveCategory(state,category){

        state.category=category;

        localStorage.setItem(CATEGORY_KEY,JSON.stringify(category))
    },
      //保存答对自动下一题设置
    saveAutoNext(state,auto){
        state.autoNext=auto
        localStorage.setItem(AUTO_NEXT_KEY,auto);
    },
      //保存答对自动移除错题设置
      saveAutoRemove(state,auto){
          state.autoRemove=auto
          localStorage.setItem(AUTO_REMOVE_KEY,auto);
      },
      //保存背题模式设置
      saveRememberMode(state,mode){
          state.rememberMode=mode
          localStorage.setItem(REMEMBER_MODE_KEY,mode);
      }
  },

  actions:{
     async logout(){

         await sessionStorage.removeItem("user")
         await sessionStorage.removeItem("token")
          router.replace('/')
      }
  }

}

// 状态机模块
import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import video from './video'
import category from './category'

Vue.use(Vuex)

//初始化状态机对象
const store = new Vuex.Store({
  modules:{
    user,
    video,
    category
  }
})



export default store

const CATEGORY_LIST_KEY = "STUDY_CATEGORY_LIST";
export default {
    namespaced: true,

    state:()=>({

        //科目列表
        categories:JSON.parse(sessionStorage.getItem(CATEGORY_LIST_KEY)||'[]')

    }),
    mutations:{
        //保存科目列表
        saveCategories(state,categories){

            state.categories=categories;

            sessionStorage.setItem(CATEGORY_LIST_KEY,JSON.stringify(categories))
        }
    }



}

import router from '../router/index' 
export default {
    namespaced:true,
    state:()=>({
        videoInfo:null, //存放用户登录信息
    }),
    mutations:{
        videoInfoMut(state,info){
        state.videoInfo = info
        },
    }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/icon/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import countdown from 'kenyang-countdown-vue'
import Print from 'vue-print-nb'
import VueLazyload from 'vue-lazyload'
import 'animate.css'     //动画样式模块
import VueFullscreen from 'vue-fullscreen'
import 'current-script-polyfill'
import '@/utils/dialogDrag'


Vue.config.productionTip = false

Vue.use(Print);   //打印机组件
Vue.use(countdown);
Vue.use(ElementUI);
Vue.use(VueLazyload)
Vue.use(VueFullscreen)

//事件总线
Vue.prototype.bus = new Vue();
//监听分类变化key
Vue.prototype.ON_CATEGORY_CHANGE="on_category_change";

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

